@import "../style/colors.scss";
@import "../style/mixins.scss";

.index-layout--root {
  .index-layout--scroll-container {
    position: absolute;
    bottom: auto;
    width: 100%;
    background: $white;
    top: 68px;
    right: 0;
    left: 0;

    .index-layout--components {
      margin: 0 auto;
      background: #fff;

      .tell-tur-cms--intro-box,
      .tell-tur-cms--main-content {
        div {
          max-width: 960px;
          margin: 0 auto;
        }
      }

      .link {
        position: relative;
        text-decoration: none;
        color: $primaryFontColor;
        z-index: 10;
        &::before {
          content: "";
          @include absolute($bottom: -1px, $left: 0);
          @include size(100%, 2px);
          display: block;
          background: rgba($linkColor, 0.2);
          transition: height 0.1s linear;
          z-index: -1;
        }
        &:hover::before {
          height: 25px;
        }
      }
    }
    .tell-tur-cms--main-content {
      position: relative;
      z-index: 10;
      background: $green;

      &::before {
        @include absolute(0, 0, 0, 0);
        z-index: -10;
        content: "";
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 48 64' width='48' height='64'><path d='M0 32 L0 48 L24 16 L48 48 L48 32 L24 0' fill='rgb(0,92,64)' /></svg>")
            0 0,
          url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 48 64' width='48' height='64'><path d='M0 32 L0 48 L24 16 L48 48 L48 32 L24 0' fill='rgb(0,92,64)' /></svg>")
            0 50px $green;
        background-size: 100px 100px;
      }

      &::after {
        @include absolute(0, 0, 0, 0);
        z-index: -8;
        background: linear-gradient(180deg, rgba($green, 0) 0%, rgba($green, 1) 50%);
        content: "";
      }
    }
  }
}
