@import '../../style/colors.scss';
@import '../../style/mixins.scss';

.tell-tur-page {
  .tell-tur-page--intro-text {
    margin-bottom: 20px;
  }
  .competition-page__wrapper--members {
    .wrapper__members--member {
      margin-bottom: 10px;
      font-weight: bold;
    }

    .members__member--competitions-list {
      padding: 0;
      list-style-type: none;
      .member__competition {
        padding: 10px;
        border: 2px solid lighten($blue, 35);
        border-radius: 2px;
        background: $white;
        font-size: 15px;
        margin-bottom: 10px;
        position: relative;

        button {
          @include absolute(50%, 10px);
          background: transparent;
          transform: translateY(-50%);

          .icon {
            @include square(17px);
            > svg {
              @include square(17px);
              fill: $krevende;
            }
          }
        }
      }
    }
  }
  .competition-page--no-joined-competitons {
    font-style: italic;
  }
}
