@import '../../style/colors.scss';
@import '../../style/mixins.scss';

.tell-tur-competitions__wrapper {
  position: relative;
  padding: 40px 20px;

  @media (max-width: 768px) {
    padding: 20px;
  }

  @media (max-width: 425px) {
    padding: 10px;
  }

  .wrapper__description {
    margin-bottom: 25px;
    h3 {
      font-weight: 900;
      color: $themeColor;
      margin-bottom: 30px;
    }

    & > p {
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 30px;
    }

    .btn-link {
      display: block;
      color: #333;
      text-decoration: underline;
      font-size: 16px;
      &:last-child {
        margin-bottom: 30px;
      }
    }
  }

  .wrapper__loader {
    height: 60px;
    width: 60px;
    margin: 250px auto;
  }
  .wrapper__no-competitions {
    margin-top: 20px;
    font-style: italic;
  }
}
