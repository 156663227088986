@import "../style/colors.scss";
@import "../style/mixins.scss";

.tell-tur--friluftsrad-list-page {
  padding: 40px 0;
  .tell-tur--friluftsrad-list-title {
    text-align: center;
    max-width: 60%;
    margin: 0 auto;

    h2 {
      color: $themeColor;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 24px;
      white-space: nowrap;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        font-size: 0.8em;
      }
    }

    p {
      font-size: 14px;
      line-height: 1;
      margin-bottom: 30px;
      text-align: left;
    }
  }

  .tell-tur--friluftsrad-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
    justify-content: center;

    .tell-tur--friluftsrad-container {
      position: relative;
      max-width: 320px;
      min-width: 320px;
      height: 230px;
      flex: 1 1 320px;
      margin: 30px;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);

      > img {
          max-width: 320px;
      }

      &:hover {
        cursor: pointer;
      }

      footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        color: $white;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          object-fit: fill;
          width: 100%;
          z-index: 1;
        }

        h4 {
          margin: 0;
          z-index: 2;
        }
      }
    }
  }
}
