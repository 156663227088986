@import '../style/colors.scss';

.checkbox--container {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 1px solid $black;
  padding-top: 2px;
  padding-left: 0px;
  border-radius: 1px;
  vertical-align: middle;

  .icon {
    height: 18px;
    > svg {
      height: 18px;
    }
  }
}
