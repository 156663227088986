@import '../style/colors.scss';

.tell-tur-page.registrertur {
  span.tell-tur--register-trip-form {
    display: flex;
    justify-content: space-between;

    & > span {
      flex: 2 1;
      margin-right: 50px;
      @media (max-width: 1024px) {
        margin-right: auto;
        padding: 0 40px;
      }
      @media (max-width: 640px) {
        padding: 0 20px;
      }
    }
    img {
      flex: 1 1;
      width: 250px;
      height: 100%;

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  .btn-primary {
    background: $blue;
    &:hover {
      background: darken($blue, 5);
    }
  }
  .register-trip-page--app-container {
    margin: 0 auto;
    position: relative;
    img {
      max-width: 100%;
    }

    .register-trip-page--app-btn-container {
      position: absolute;
      right: 8%;
      bottom: 8%;
      min-height: 50px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.5);

      @media (max-width: 425px) {
        bottom: 0px;
      }

      .register-trip-page--app-link {
        display: block;
        width: auto;
        height: auto;
        padding: 6px;
        cursor: pointer;

        img {
          @media (max-width: 768px) {
            width: 150px;
          }

          @media (max-width: 425px) {
            width: 130px;
          }
        }
      }
    }
  }
}
