@import './colors.scss';

@mixin form-control-style {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px $grey solid;
  color: $black;
  background-color: $white;
  font-size: 16px;
  border-radius: 0px;
  box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.08);
}

@mixin btn-common-properties {
  border-radius: 4px;
  color: $white;
  text-decoration: none;
  font-weight: 600;
}

@mixin custom-buttons($bgColor: $dark-blue) {
  color: $white;
  background-color: $bgColor;
  border-color: $blue;
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 900;
  line-height: 2;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 1px;
  margin-top: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
}

@mixin size($width: $width, $height: $height) {
  width: $width;
  height: $height;
}
@mixin square($size) {
  @include size($size, $size);
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin absolute($args...) {
  @include position(absolute, $args...);
}
@mixin relative($args...) {
  @include position(relative, $args...);
}
@mixin fixed($args...) {
  @include position(fixed, $args...);
}
