@import '../style/colors.scss';

.tell-tur-page {
  background: $light-blue;
  .popup-box--btn-container {
    justify-content: center;
  }

  .loader {
    top: calc(50vh - 108px);
  }

  .checkbox-wrapper {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 20px 0 40px;
    cursor: pointer;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      background: transparent;
      border: 2px solid $secondaryFontColor;
      border-radius: 2px;

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 6px;
        top: 2px;
        width: 6px;
        height: 11px;
        border: solid $secondaryFontColor;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input:checked ~ .checkmark {
      &:after {
        display: block;
      }
    }
  }
}

.editable-dropdown-edit-toggle {
  display: inline;
  padding-bottom: 5px;
  cursor: pointer;

  > span.icon {
    display: inline-block;
    vertical-align: middle;

    > svg {
      height: 12px;
      width: 12px;
    }
  }
}
