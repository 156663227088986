@import "../style/mixins.scss";

.telltur-parking {
  position: fixed;
  top: 115px;
  left: 10px;
  padding: 12px;
  background: $white;
  min-width: 200px;
  border: 1px solid #eee;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .content {
    display: inline-block;
    max-width: 300px;

    p {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
    }
    .title {
      display: inline-block;
      margin-bottom: 10px;
      padding-top: 10px;
      font-weight: bold;
      max-width: 250px;
    }
  }

  img {
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
  }
  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 18px;
    padding: 0;
    background: transparent;
    &:focus {
      outline: 0;
    }
    .icon {
      fill: rgba(0, 0, 0, 0.6);
    }
  }
}
