@import '../style/colors.scss';

.popup-box--overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.6);

  @media (max-width: 768px) {
    position: absolute;
  }

  .popup-box--container {
    position: fixed;
    width: 640px;
    background: $white;
    z-index: 1000;
    border: 1px solid #eee;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
      left: 20px;
      right: 20px;
      width: auto;
      transform: translateY(-50%);
    }

    .popup-box--content {
      overflow-y: auto;
      padding: 20px;
      max-height: calc(80vh - 100px);
    }

    .popup-box--btn-container {
      padding: 10px 20px;
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .popup-box--checkbox-container {
        flex: 1 1 150px;
        height: 50px;
        padding-top: 15px;

        .checkbox--container {
          margin-right: 10px;
        }

        label {
          font-size: 12px;
        }
      }

      .popup-box--close-btn {
        flex: 1 1 200px;
        height: 50px;
        max-width: 200px;
        background: $dark-blue;
        color: $white;
        right: 20px;
        text-transform: uppercase;
      }
    }
  }
}
