@import '../style/colors.scss';
@import '../style/mixins.scss';

body.modal-open {
  overflow: hidden;
}

.modal--overlay {
  @include fixed(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.35);
  display: block;
  z-index: 100;
  overflow: auto;

  .modal--content {
    @include absolute(50%, $left: 50%);
    background: darken($white, 2);
    border: 1px solid #eee;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    width: 90%;
    max-width: 690px;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    z-index: 1000;

    @media (max-width: 768px) {
      top: 75px;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      z-index: 1000;
      margin-bottom: 30px;
    }
  }
}
