@import '../style/colors.scss';

.tell-tur-page.addMember {
  display: flex;
  justify-content: space-around;
  img {
    width: 250px;
    height: 100%;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  .tell-tur-page-form-container {
    .tell-tur-form--control.flex-row {
      > input.input-small {
        width: 20%;
        position: absolute;
        top: 45px;
        right: 0;
      }
      > input.input-large {
        width: 70%;
      }

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .flex-members-large {
        flex: 0 0 70%;
        margin-right: 5%;
        max-width: 70%;
      }

      .flex-members-small {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }

    .tell-tur-page--intro-text > h3 {
      font-size: 14px;
      font-weight: 700;
      margin-top: 30px;
    }

    .member-list {
      width: 100%;
      font-weight: 700;
      margin-bottom: 90px;

      &--container {
        margin-bottom: 20px;
        padding-bottom: 10px;
        position: relative;
      }

      &--row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        position: relative;
        &::after {
          position: absolute;
          right: 0;
          bottom: -7px;
          left: 0;
          content: '';
          width: 80%;
          height: 1px;
          background: #000;
          opacity: 10%;
        }

        .cell {
          .checkbox {
            float: left;
            display: block;
            height: 20px;
            width: 20px;
            border: 1px solid #000;
            margin: 0px;
            padding-top: 3px;
            padding-left: 1px;
            border-radius: 1px;
          }

          .icon {
            min-height: 12px;
            min-width: 12px;

            > svg {
              height: 12px;
            }
          }
        }

        .name-cell {
          flex: 0 0 50%;
        }

        .year-cell {
          flex: 0 0 20%;
        }

        .delete-cell {
          flex: 0 0 30%;
          font-weight: 100;

          > span {
            display: block;
            padding-left: 30px;
          }
        }
      }
    }
  }
}
