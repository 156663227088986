@import '../../style/colors.scss';

.tell-tur-results {
  &--navigation {
    &-container {
      width: 100%;
      display: block;
      margin-bottom: -1.1px;
    }

    &-options {
      background: $resultCard;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .switch {
        margin: 0;
      }

      .tell-tur-form--control {
        align-self: flex-end;
        margin-left: auto;
        width: auto;
        .form-control--infoText {
          display: inline;
          font-size: 13px;
          font-weight: bold;
          margin-right: 5px;
        }
        select {
          display: inline-block;
          padding: 0 5px;
          width: 80px;
          font-size: 13px;
          font-weight: bold;
        }
      }
    }

    &-tab {
      border-bottom: 1px solid $inactiveTab;
      background-color: $inactiveTab;
      color: $black;
      font-weight: bold;
      position: relative;
      display: inline-block;
      padding: 15px 20px;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 5px;
      }

      &.active {
        color: $black;
        background-color: $resultCard;
        border-bottom: 1px solid $resultCard;
      }
    }
    &-tab.disabled {
      opacity: 0.6;
      pointer-events: none;
      border-bottom: 1px solid $resultCard;
    }
  }

  .result-page--result-list-container {
    margin-bottom: 10px;
  }
}
