@import '../../style/colors.scss';
@import '../../style/mixins.scss';

.tell-tur__competition-results--wrapper {
  .competition-results__wrapper--competitions-list {
    .competitions-list__competition--container {
      border: 1px solid $blue;
      border-radius: 2px;
      background: $white;
      margin-bottom: 10px;

      .container__title--button {
        background: $white;
        padding: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between; 
        &.ongoing {
          background: $light-blue;
        }

        .content__label {
          font-size: 15px;
          font-weight: 600;

          &.active {
            font-weight: bold;
          }
          .content__label-date {
            font-size: 13px;
            font-weight: normal;
          }
        }

        button {
          background: transparent;
          .icon {
            @include square(17px);

            > svg {
              @include square(17px);
            }
          }
        }
      }
      .competition__container--content {
        padding: 0 18px;
        max-height: 0;
        overflow: hidden;
      }

      .competition__container--content.active {
        max-height: 100%;
        padding: 0;

        .result-list {
          border: none;
          padding: 0;
        }
      }
    }
  }

  .competition-results--no-joined-competitions {
    font-style: italic;
  }
}
