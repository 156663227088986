@import '../style/mixins';

.switch {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid $dark-blue;
  overflow: hidden;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  &.disable {
    pointer-events: none;
    opacity: 0.6;
  }

  & > .switch-btn {
    flex: 1 0 0;
    background: transparent;
    color: $dark-blue;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    padding: 4px 10px;
  }

  .active {
    background: $dark-blue;
    color: white;
  }
}
