@import "../style/colors.scss";
@import "../style/mixins.scss";

.telltur--article {
  padding: 15px;

  img {
    max-width: 100%;
    height: auto;
  }

  .telltur--article-gradering-img {
    float: left;
  }

  .telltur--article--header-container {
    display: inline-block;
    padding: 10px 10px 0 10px;

    h1 {
      font-size: 27px;
      margin: 5px 0 5px;
    }

    .telltur--article-friluftsrad {
      font-size: 15px;
      font-weight: 400;
    }
  }

  .telltur--image {
    margin: 0px 0px 10px;
    text-align: center;

    img {
      width: 100%;
      object-fit: cover;
      max-height: 350px;
      margin: 0 auto;
    }
  }

  .telltur-article-flex-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: $pink;
    padding: 10px 0;
    color: $white;
    justify-content: space-between;

    &.time-info {
      background: transparent;
      border-top: 1px solid $mapColor;
      border-bottom: 1px solid $mapColor;
      color: $timeFont;
      font-size: 13px;
    }

    div {
      padding: 0 10px;

      .telltur-article--meta-cols-value {
        font-weight: 600;

        &::before {
          content: " ";
        }
      }
    }

    .float-right {
      text-align: right;
    }
  }
  .content {
    padding: 10px 0;
    font-size: 16px;

    img {
      display: block;
    }
  }

  div.telltur-article--share {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    @media (max-width: 428px) {
      flex-wrap: wrap;
    }

    .btn-link {
      color: $black;
      font-size: 13px;
      font-weight: 600;
    }

    button {
      background: $white;
      // width: 50%;
      display: inline-block;
      padding: 5px 10px;

      @media (max-width: 1024px) {
        padding: 5px;
      }

      span.icon {
        display: inline-block;
        margin-right: 6px;
        vertical-align: middle;

        svg {
          width: 22px;
          height: 22px;
          position: relative;
          fill: #000;
          top: 0;
          left: 0;
          transform: none;
        }
      }
    }
  }

  .telltur-article--notification {
    text-align: center;
    background: $yellow;

    p {
      font-weight: 700;
      font-size: 14px;
      padding: 10px 0;
      margin: 0;
    }
  }

  .telltur-article--moved {
    text-align: center;
    background: $red;

    p {
      font-weight: 700;
      font-size: 14px;
      padding: 10px 0;
      margin: 0;
      color: $white;
    }
  }

  .popup-box--overlay {
    position: fixed;

    .popup-box--container {
      @media (max-width: 425px) {
        bottom: auto;
        top: auto;
        top: 50%;
        transform: translateY(-50%);
      }

      .popup-box--content {
        font-size: 16px;
      }

      .telltur-article--share-container {
        width: 100%;
        margin-top: 20px;

        input.telltur-article--sharelink {
          @include form-control-style();
        }

        button {
          margin-top: 10px;
          color: $black;
          width: 100%;
          height: 50px;
          background: $light-green;

          &:focus,
          &:active {
            border-color: none;
            outline: none;
          }

          &.link-copied {
            background: $grey;
          }
        }
      }

      .popup-box--btn-container {
        float: right;
        button {
          height: 50px;
        }
      }
    }
  }
}
