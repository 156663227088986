@import '../style/colors.scss';
@import '../style/mixins.scss';

.text-editable-control {
  &--value {
    display: inline-block;
    font-weight: 700;
  }

  &--edit-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    input {
      flex: 1 1 150px;
      margin-right: 10px;
      display: block;
    }

    button {
      flex: 0 0 100px;

      @include btn-common-properties();
      border-color: $blue;
      background: $blue;
      height: auto;

      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &--edit-toggle {
    display: inline-block;
    margin-left: 15px;
    padding-bottom: 5px;

    > span > svg {
      height: 12px;
      width: 12px;
      fill: $black;
      cursor: pointer;
    }
  }
}
