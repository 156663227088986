@import "../../style/colors.scss";
@import "../../style/mixins.scss";

.result-list {
  border-top: transparent;
  padding: 0 5px 0 5px;
  margin-bottom: 10px;
  background: $resultCard;
  position: relative;

  &--row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px 10px;
    background: white;

    &:not(:last-child) {
      border-bottom: 1px solid $resultCard;
    }
    &.header {
      border-bottom: 4px solid $resultCard;
      background: $resultCard;
      padding: 2px 10px;

      .result-card--search {
        font-weight: 300;
        font-size: 13px;
        padding: 5px 12px;
        min-width: 300px;
        border: 1px solid $blue;
        margin-left: -10px;
      }
    }
  }
  .is-member {
    background: #edf7fe;
  }
  .is-member > * {
    font-weight: 600;
  }

  &--header {
    font-weight: 700;
  }

  &--column0 {
    flex: 0 0 30px;
    text-align: right;
  }

  &--column1 {
    margin-left: 20px;
    flex: 1 1 100px;
    word-break: break-all;
  }

  &--column2 {
    flex: 0 0 50px;
    text-align: right;
  }

  &--column3 {
    flex: 0 0 120px;
    text-align: right;
  }

  &--column4 {
    flex: 0 0 100px;
    text-align: right;
  }

  .result-list--loading-container {
    @include absolute(0, 0, 0, 0);
    @include square(60px);
    margin: auto;
  }

  .result-page--result-list-container__page-options {
    padding: 5px 10px;
    .telltur--pagination__list-page-item.active {
      border-radius: 50%;
      background: #0072bc;
    }
  }
}
