@import "../../style/colors.scss";
@import "../../style/mixins.scss";

.competiton__container {
  margin-bottom: 10px;
  color: $white;

  &.active {
    border: 1px solid $light-green;
    color: $black;
    .container__title--button {
      background: $white;

      .icon > svg {
        fill: $light-green;
      }
    }
    .container__content {
      min-height: 150px;
      max-height: 100%;
      padding: 10px 18px 20px 18px;
      position: relative;
      font-size: 16px;

      .loader {
        @include absolute();
        @include square(25px);
        display: block;
      }

      .container__content--description {
        margin-bottom: 20px;
      }

      .container__content--media {
        max-width: 100%;
        img {
          max-width: 100%;
          border-radius: 3px;
        }
      }

      .content__label {
        font-style: italic;
      }
      .turmal {
        .content__label {
          font-weight: bold;
          font-style: normal;
        }
      }

      .btn.btn-primary {
        display: block;
        margin: 15px auto 0 auto;
        &:active,
        &:focus {
          background: darken($light-green, 5);
        }
      }
    }
  }

  .container__title--button {
    background: $light-green;
    cursor: pointer;
    padding: 10px 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .content__label {
      font-weight: 900;
      font-size: 18px;
    }

    .icon {
      @include square(17px);
      margin: auto 0;
      > svg {
        fill: $white;
        height: 17px;
      }
    }
  }
  .container__content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;

    .loader {
      display: none;
    }
  }
  .competitions-list--destinations {
    .turmal {
      font-style: italic;
      a {
        color: $black;
        text-decoration: underline;
      }
    }
  }
  .competitions-list--terms {
    margin: 15px 0;
  }
  .competitions-list--bold-text {
    font-weight: 600;
    display: inline-block;
    padding: 5px 0;
  }
}
