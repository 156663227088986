@import "../style/colors.scss";
@import "../style/mixins.scss";

.telltur--index {
  background: $light-blue;

  .telltur-index--banners {
    position: relative;
    height: 100%;
    .banner-warning {
      background: $dark-yellow;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: stretch;
      position: relative;
      width: 100vw;

      .x {
        height: auto;
        min-width: 50px;
        max-width: 50px;
        cursor: pointer;

        .content {
          margin-left: 10px;
          width: 30px;
        }
      }

      &.friluftsraad {
        top: 68px;
      }
    }

    .covid {
      font-size: 16px;
      min-height: 40px;
      width: auto;
      margin: 0 auto;
      color: $secondaryFontColor;
      text-decoration: none;
      cursor: pointer;

      .text {
        display: block;
        text-align: center;
        margin: 9px 42px;
        font-weight: bold;
      }

      .icon {
        display: inline-block;
        height: 20px;
        width: 20px;
        vertical-align: text-top;
      }

      .chevron {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
      }

      .warning {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .telltur-index--active-competitions {
      text-align: center;
      background: $activeCompetitionToast;
      height: 40px;
      display: table;
      width: 100%;
      box-shadow: 0px 0px 6px $black;

      p {
        display: table-cell;
        vertical-align: middle;
        margin: 0;
        font-weight: 700;
        color: $secondaryFontColor;
        a {
          color: $secondaryFontColor;
          text-decoration: underline;
        }
      }
    }
  }

  .telltur-index--img-container {
    @include relative(0, 0, $left: 0);
    text-align: center;
    .telltur--index-image {
      width: 100%;
      height: 100%;
      min-height: 220px;
      max-height: 370px;
      object-fit: cover;
      object-position: 50% 20%;

      @media (max-width: 643px) {
        min-height: 400px;
        max-height: none;
      }
    }

    .button-container {
      @include absolute(calc(50% - 48px), 0, 0, 0);

      @media (max-width: 643px) {
        top: 50%;
        transform: translateY(-50%);
      }

      .btn {
        font-weight: 900;
        font-size: 21px;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
        padding: 20px 25px;
        margin: 10px;
        display: inline-flex;
        align-items: center;

        &.active-competitions--btn {
          background: $activeCompetitionToast;
          &:hover {
            background: darken($activeCompetitionToast, 5);
          }
        }

        &:first-child {
          background: $pink;
          .icon {
            svg {
              @include square(36px);
              fill: none;
              stroke-width: 2px;
              stroke: $white;
            }
          }
          &:hover {
            background: darken($pink, 5);
          }
        }
        .icon {
          @include square(36px);
          display: inline-block;
          margin-right: 10px;
          svg {
            @include square(36px);
            fill: $white;
          }
        }
      }
    }
  }

  .popup-box--container {
    p a {
      text-decoration: underline;
      color: #333;
    }
  }

  .telltur-index--description-container {
    position: relative;
    padding: 80px 0 120px;
    max-width: 1280px;
    margin: 0 auto;
    color: $secondaryFontColor;
    font-size: 19px;
    line-height: 27px;

    @media (max-width: 768px) {
      padding: 80px 40px;
    }

    .telltur-index--description {
      text-align: justify;
      margin: 20px auto;
      max-width: 690px;

      .strong {
        font-weight: 900;
      }
    }

    h3 {
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 27px;
      margin-top: 100px;
    }

    .illustration {
      @include square(200px);
      position: absolute;

      @media (max-width: 1280px) {
        transform: scale(0.7);
      }

      &.benk {
        @include square(255px);
        bottom: 70px;
        right: 0;
        @media (max-width: 1280px) {
          right: 90px;
          bottom: 60px;
        }
        @media (max-width: 1100px) {
          right: 60px;
        }
        @media (max-width: 1000px) {
          right: 30px;
          bottom: 40px;
        }
        @media (max-width: 900px) {
          right: -20px;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
      &.fiske {
        top: 140px;
        left: 20px;
        @media (max-width: 1100px) {
          display: none;
        }
      }
      &.sykle {
        bottom: 60px;
        left: 160px;
        @media (max-width: 1280px) {
          left: 90px;
        }
        @media (max-width: 1000px) {
          left: 30px;
        }
        @media (max-width: 900px) {
          left: 10px;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}
