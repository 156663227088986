@import "../style/colors.scss";

.trip-list {
  margin: 10px 0;
  border: 1px solid $blue;
  padding: 20px;

  &--municipality-rows-wrapper {
    &:not(:last-child) {
      padding-bottom: 10px;
    }

    &:not(:first-child) {
      padding-top: 10px;
      border-top: 2px solid $light-green;
    }
  }

  &--municipality-name {
    font-weight: 700;
  }

  &-row {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: 1px 0;
    margin: 1px 0;
    line-height: 1.8;

    .header-cell {
      font-weight: 700;
    }

    .date-cell {
      flex: 0 0 90px;
    }

    .destination-cell {
      flex: 1 1 auto;
    }

    .points-cell {
      flex: 0 0 50px;
      margin-right: 10px;
      text-align: center;
    }

    .delete-cell {
      flex: 0 0 60px;
    }
  }

  .trip-list--loader {
    height: 60px;
    width: 60px;
    margin: 0 auto;
  }
}

.tell-tur-page {
  .tell-tur-form {
    .form-control-infoText {
      margin-bottom: 10px;
    }
    .form-control-inlineFlex {
      display: flex;

      .tell-tur-form--control {
        .editable-search-dropdown__control {
          height: auto;
        }

        &:first-child {
          flex: 2;
        }
        &:last-child {
          margin-left: 10px;
          flex: 1;
        }
      }
      @media (max-width: 768px) {
        display: block;
        .tell-tur-form--control:first-child {
        }
        .tell-tur-form--control:last-child {
          margin: 10px 0 0 0;
          display: block;
        }
      }
    }
  }
}
