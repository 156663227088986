@import '../style//colors.scss';
@import '../style/mixins.scss';

.telltur--pagination__container {
  display: inline-block;
  .telltur--pagination__list {
    display: inline-block;
    margin: 0;
    padding: 0;

    &-page-item {
      display: inline;
      padding: 0 5px;

      &.active button.btn-link {
        text-decoration: none;
        color: $white;
      }
    }
  }
}
