@import "../style/colors.scss";

.article-detail-view--container {
  font-size: 14px;
  line-height: 1.6;

  @media (max-width: 768px) {
    padding: 10px;
    margin-top: 60vh;
  }

  .article-window {
    background: #fff;
    width: 460px;
    position: absolute;
    top: 78px;
    bottom: 20px;
    right: 10px;
    max-height: 100%;
    border: 1px solid #eee;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: auto;

    @media (max-width: 1024px) {
      width: 400px;
    }

    @media (max-width: 768px) {
      right: 10px;
      left: 10px;
      width: auto;
      bottom: auto;
      top: 60%;
      max-height: none;
      margin-bottom: 20px;
      overflow: inherit;
      min-height: calc(60%);
    }
  }

  .article-detail-view--close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 32px;
    width: 32px;
    fill: $white;
    padding: 0;
    background: $pink;
    border-radius: 50%;

    .icon {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .article-detail-view--children {
    padding: 0 15px 15px;

    .instafeed--container {
      .instafeed--feed {
        .instafeed-component--item {
          width: 40%;
          flex: none;

          img {
            width: 80%;
            max-height: 228px;
            height: 70%;
            object-fit: cover;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@media print {
  @page {
    size: auto;
    margin: 0mm;
    text-align: center;
  }
  body {
    div.react-openlayers--map {
      position: static;
      width: 100%;
      height: 100%;
      max-height: 800px;

      .ol-scale-line {
        display: none;
      }
    }

    .react-openlayers--overlay {
      position: static;

      .zoom-control--root {
        display: none;
      }

      .telltur-parking {
        position: absolute;
        background: $white !important;
        top: 10px;

        .close-btn {
          display: none;
        }
      }

      .map-layout--root {
        position: static;

        .nav-bar--container {
          display: none;
        }
        .map-layout--components {
          .article-detail-view--container {
            position: static;
            top: 0;
            margin-top: 0;

            .article-window {
              position: static;
              width: 100%;
              top: 0;
              right: 0;
              max-height: none;

              .telltur--article {
                .telltur--image,
                .telltur-article--share {
                  display: none;
                }
              }

              .article-detail-view--children {
                button {
                  display: none;
                }
              }

              .destination-stats--container {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
