@import '../style/colors.scss';

div.map-layout--root {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;

  & > * {
    pointer-events: all;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
}
