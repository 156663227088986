// color variables

$white: #ffffff;
$black: #000000;

$grey: #cccccc;
$dark-gray: #434343;
$light-grey: #ebe8e5;
$darkish-grey: #d7d2cb;

$pink: #c6579a;

$blue: #0072ba;
$light-blue: #d7e9f5;
$dark-blue: #003b5c;

$yellow: #f1b434;
$dark-yellow: #f5ca71;

$orange: #f9e6bd;
$dark-orange: #cf7615;
$light-orange: #f0a83e;

$green: #00573f;
$light-green: #00aa53;
$bg-green: #d8f2e5;
$dark-green: #003118;

$red: #990000;

// globals
$themeColor: $dark-blue;
$btnColor: $orange;
$linkColor: $blue;
$primaryFontColor: $dark-gray;
$secondaryFontColor: #191919;

$resultCard: #97c6e4;
$inactiveTab: #aca39a;
$activePagination: $blue;

$activeCompetitionToast: $yellow;

$mapColor: #e2f4ff;
$timeFont: #444;
$enkel: #008642;
$middels: #0072ba;
$krevende: #9f0004;
$ekspert: #000;
$warning: #fcf8e3;

$overlay: rgba($black, 0.6);
