@import "../style/colors.scss";
@import "../style/mixins.scss";

.nav-bar--container {
  @include size(100%, 68px);
  position: fixed;
  background: $themeColor;
  color: $white;
  z-index: 100;

  .nav-bar--header-image {
    @include absolute($top: 10px, $left: 10px);
    max-height: 45px;
    z-index: 100;
  }

  .nav-bar--search-button {
    @include absolute(0, 0);
    @include square(68px);
    background: $themeColor;

    > span > svg {
      @include square(68px);
      padding: 15px;
      max-height: 60px;
      max-width: 60px;
      fill: $white;
    }
  }

  .nav-bar--menu-active,
  .nav-bar--search-active {
    background: $blue;
    transition: background 0.2s;
  }

  .nav-bar--search {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2, visibility 0.2s;
  }

  .nav-bar--search-hidden {
    visibility: hidden;
    opacity: 0;
  }

  .nav-bar--menu {
    @include absolute($right: 68px);
    @include size(68px, 100%);
    text-align: right;
    user-select: none;
    z-index: 2;

    .nav-bar--hamburger {
      @include square(100%);
      display: block;
      margin: 0 0 -48px;
      opacity: 0;
      cursor: pointer;
      z-index: 2;

      & ~ span {
        @include size(30px, 4px);
        display: block;
        margin: 4px auto;
        border-radius: 2px;
        background: #fff;
        transition: transform ease-in-out 200ms, opacity ease-in-out 100ms;
        opacity: 1;
        pointer-events: none;
      }

      &:checked ~ span:nth-child(2) {
        transform: translateY(8px) translateX(0px) rotate(-45deg);
      }

      &:checked ~ span:nth-child(4) {
        transform: translateY(-8px) translateX(0px) rotate(45deg);
      }

      &:checked ~ span:nth-child(3) {
        opacity: 0;
      }
    }

    .nav-bar--hamburger:checked ~ .cms--nav {
      height: auto;
      opacity: 1;
      visibility: visible;
    }

    .cms--nav {
      @include absolute(68px, -68px);
      @include size(calc(100vw - 40px), 0);
      visibility: hidden;
      opacity: 0;
      display: block;
      background: $blue;
      max-width: 380px;
      text-align: left;
      opacity: 0;
      transform: opacity 200ms;
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.3);
      transition: opacity 0.2s;
      max-height: calc(100vh - 68px);
      margin-bottom: 20px;
      z-index: 10000;
      padding-bottom: 40px;

      a {
        color: $white;
        font-size: 21px;
        font-weight: 700;
      }
      a:link {
        display: block;
        padding: 5px 40px;
      }
      a:link,
      a:visited {
        color: white;
      }

      a:link:after {
        display: none;
      }
    }

    .cms--nav,
    .nav-bar--profile-menu,
    .nav-bar--omTellTur-menu {
      padding-top: 40px;
    }
    .nav-bar--profile-menu {
      min-height: 190px;
      .nav-bar--profile-menu-logginn {
        background: $light-green;
        border-radius: 4px;
        margin: 0 40px 0 20px;
        text-decoration: none;
        padding: 10px 20px !important;
      }
    }
  }

  .banner-warning {
    background: $dark-yellow;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: stretch;
    position: absolute;
    width: 100vw;
    top: 68px;

    .x {
      height: auto;
      min-width: 50px;
      max-width: 50px;
      cursor: pointer;

      .content {
        margin-left: 10px;
        width: 30px;
      }
    }
  }

  .covid {
    font-size: 16px;
    min-height: 40px;
    width: auto;
    margin: 0 auto;
    color: $secondaryFontColor;
    text-decoration: none;
    cursor: pointer;

    .text {
      display: block;
      text-align: center;
      margin: 9px auto;
      font-weight: bold;
    }

    .icon {
      display: inline-block;
      height: 20px;
      width: 20px;
      vertical-align: text-top;
    }

    .chevron {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
    }

    .warning {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
