@import "../style/colors.scss";

.core-layout--root {
  .core-layout--scroll-container {
    position: absolute;
    bottom: auto;
    width: 100%;
    background: $light-blue;
    top: 68px;
    right: 0;
    left: 0;

    &.friluftsrad {
      background: $bg-green;
      .core-layout--components {
        max-width: 1280px;
      }
    }

    .core-layout--components {
      margin: 0 auto;
      max-width: 960px;
      margin-bottom: 20px;
      min-height: calc(100vh - 278px);
      @media (max-width: 1024px) {
        min-height: calc(100vh - 475px);
      }

      div.tell-tur-cms {
        padding: 40px 10px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          text-transform: uppercase;
          font-weight: 900;
          text-align: center;
          color: $themeColor;
        }
        h4 {
          font-weight: 700;
        }

        a {
          color: $themeColor;
          text-decoration: underline;
        }
      }
    }
  }
}
