@import "../style/colors.scss";

.article-view--container {
  .article-view--content {
    background: $white;
    position: absolute;
    width: 460px;
    top: 78px;
    right: 10px;
    bottom: 20px;
    max-height: 100%;
    padding: 15px;
    border: 1px solid #eee;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: auto;

    @media (max-width: 1024px) {
      width: 400px;
    }

    @media (max-width: 768px) {
      right: 10px;
      left: 10px;
      width: auto;
      top: 60%;
      bottom: auto;
      max-height: none;
      margin-bottom: 0;
      overflow: inherit;
      min-height: calc(60%);
    }

    button.dropdown-toggle {
      background: $pink;
      &:hover,
      :active,
      :visited {
        background: $pink;
      }
    }
  }
  .buttons {
    position: fixed;
    top: 187px;
    right: 480px;
    width: 34px;
    height: 34px;

    @media (max-width: 1024px) {
      right: 420px;
    }

    @media (max-width: 768px) {
      position: fixed;
      right: 10px;

      & > * {
        display: inline-block;
      }
    }

    .article-view--tip-btn {
      background: $blue;
      fill: $white;
      width: 100%;
      height: 100%;
      margin-bottom: 5px;

      .icon {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
