@import "./style/fonts.scss";
@import "./style/btn.scss";
@import "./style/colors.scss";
@import "./style/mixins.scss";

/* Bootstrap 4 media query breakpoints for reference

@media all and (max-width: 1199px) { ... }
@media all and (max-width: 991px) { ... }
@media all and (max-width: 768px) { ... }
@media all and (max-width: 575px) { ... }

*/

body {
  margin: 0;
  padding: 0;
  font-family: "Mulish", "Open Sans", sans-serif;
  font-size: 16px;
  color: $secondaryFontColor;

  .loader {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .error {
    color: $red;
    display: block;
  }

  .text-icon-left {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    padding-bottom: 4px;

    > span > svg {
      height: 12px;
      width: 12px;
      fill: $black;
    }
  }

  .tell-tur-link {
    color: $dark-blue;
    cursor: pointer;

    a:hover {
      text-decoration: none;
    }
  }

  span.underline {
    padding-bottom: 0px;
    border-bottom: 1px solid $blue;
  }

  .info-box {
    margin: 20px;
    padding: 15px;
    border: 3px dotted $light-green;

    > div > h3 {
      font-size: 14px;
      font-weight: 700;
    }
    &.warning {
      border: 3px dotted $orange;
    }

    &--button-row {
      width: 100%;
      height: 38px;
      display: flex;

      .checkbox {
        flex: 0 0 auto;
        width: 30px;
        height: 30px;
        width: 30px;
        border: 1px solid $black;
        margin: 3px 0px 0px 0px;
        padding-top: 3px;
        padding-left: 0px;
        border-radius: 1px;

        .icon {
          height: 21px;
          > svg {
            height: 21px;
          }
        }
      }

      .text {
        flex: 1 1 auto;
        padding-top: 8px;
        padding-left: 10px;
        font-weight: 700;
      }

      .buttons {
        flex: 1 0 150px;

        > span {
          display: block;
          text-align: right;
        }
      }
    }

    > a {
      margin-top: 15px;
    }
  }

  .vertical-spacing {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .zoom-control--root {
    position: absolute;
    top: 114px;
    left: auto;
    right: 479px;
    background: transparent;
    border-radius: 0;
    padding: 0;
    width: 90;

    @media (max-width: 1024px) {
      right: 419px;
    }

    @media (max-width: 768px) {
      right: 9px;
    }

    button {
      background: $blue !important;
      border-radius: 0;
      width: 34px;
      height: 34px;
    }
  }

  .react-openlayers--map {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: $mapColor;

    &.hide-map {
      visibility: hidden;
    }
    .ol-zoom.ol-unselectable.ol-control {
      position: absolute;
      top: 149px;
      left: auto;
      right: 479px;
      background: transparent;
      border-radius: 0;
      padding: 0;
      width: 90;

      @media (max-width: 1024px) {
        right: 419px;
      }

      @media (max-width: 870px) {
        top: 174px;
      }

      @media (max-width: 768px) {
        display: none;
      }

      button {
        background: $blue !important;
        border-radius: 0;
        width: 34px;
        height: 34px;
      }
    }

    .react-openlayers--show-location {
      &.ol-unselectable {
        &.ol-control {
          position: fixed;
          top: 78px;
          background: transparent;
          left: auto;
          right: 480px;
          width: 34px;
          height: 34px;
          border-radius: 0;
          padding: 0;

          @media (max-width: 1024px) {
            right: 420px;
          }

          @media (max-width: 768px) {
            right: 10px;
          }

          button {
            background: $blue !important;
            border-radius: 0;
            width: 34px;
            height: 34px;
            margin: 0;
          }
        }
      }
    }
  }

  .tell-tur {
    margin: 0 auto;

    // Forms
    &-form {
      position: relative;
      padding-bottom: 90px;

      &.no-buttons {
        padding-bottom: 0px;
      }

      &.no-padding {
        padding: 0px;
      }
      &--error {
        color: $orange;
        display: block;
      }

      &--control {
        width: 100%;

        label {
          margin-top: 20px;
          font-weight: 100;
        }

        input,
        select {
          @include form-control-style();
        }

        .editable-search-dropdown__control {
          height: 44px;
          border-radius: 0;
          box-shadow: none;
          background: #fff;
          border: 1px $grey solid;
        }
      }

      &--text-row {
        display: block;
        padding: 10px 0;
      }

      &--button-group {
        position: absolute;
        bottom: 30px;
        right: 0;

        button:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    // End forms

    &-page {
      padding: 40px 10px 10px 10px;
      width: 100%;

      // @media (min-width: 640px) {
      //   width: 640px;
      //   margin: 0 auto 0 auto;
      // }

      h1 {
        margin: 30px 0;
        font-weight: 900;
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
      }

      &--intro-text {
        margin: 10px 0px;
        // width: 960px;
      }

      &--footer {
        margin-top: 40px;
      }
    }
  }

  button {
    border: none;
  }
  :focus {
    outline: none;
  }
}

.u-margin-top-small {
  margin-top: 20px;
}
