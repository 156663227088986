@import '../style/colors.scss';
@import '../style/mixins.scss';

.tell-tur {
  .tell-tur-page {
    a {
      text-decoration: underline;
      color: $black;
    }
  }

  .tell-tur-cms {
    img {
      max-width: 100%;
    }

    .tell-tur--div-components {
      .instafeed--container {
        max-width: 960px;
        margin: 0 auto;
      }
    }

    .loader {
      position: fixed;
    }
  }
}
