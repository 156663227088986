@import './colors.scss';
@import './mixins.scss';

.btn {
  @include btn-common-properties();
  padding: 8px 24px;

  &-spacing-left {
    margin-left: 15px;
  }

  &-spacing-right {
    margin-right: 15px;
  }

  &-primary {
    color: $white;
    background-color: $light-green;
    border: none;

    a {
      color: $white;
    }
    &:hover {
      background: darken($light-green, 5);
    }
  }
  &-secondary {
    color: $black;
    background-color: $grey;
    border-color: $grey;

    a {
      color: $black;
    }
  }

  &-default {
    color: $black;
    background-color: $grey;

    &:hover {
      color: $white;
      background-color: darken($grey, 15);
    }
  }
}

.btn .btn-primary .btn-success {
  background-color: darken($green, 15);

  &:hover {
    background-color: $light-green;
  }
}

.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  box-shadow: none;
}

.btn-link {
  padding: 0;
  color: $black;
  font-weight: 400;
  text-transform: none;
  text-decoration: underline;

  &:hover {
    color: $black;
  }
}

.btn-uppercase {
  text-transform: uppercase;
}
