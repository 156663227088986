@import '../style/colors.scss';

.friluftsrad-page--wrapper {
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
  .friluftsrad-page--main {
    flex: 1 0 55%;
    .telltur--friluftsrad-page {
      & > h1,
      h2,
      h3,
      h4 {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 24px;
        margin-bottom: 30px;
      }

      & > h4 {
        font-weight: 700;
      }

      h2 {
        @media (max-width: 768px) {
          font-size: 1.2em;
        }
      }
      a {
        color: $black;
        text-decoration: underline;
      }
      .kommune-container {
        ul.kommune-list {
          list-style: none;
          padding: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          li {
            min-width: 250px;
            a {
              img {
                padding: 10px;
                width: 100px;
                object-fit: contain;
                height: 120px;
              }
            }
          }
        }
      }

      main {
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          max-width: 300px;
          margin-top: 10px;
        }
      }
    }
  }
  .friluftsrad-page--aside {
    background: $white;
    flex: 1 0 45%;
    align-self: flex-start;
    margin-left: 10px;

    @media (max-width: 768px) {
      margin: 0 20px 40px 20px;
    }
    @media (max-width: 425px) {
      margin: 20px;
    }
  }
}
