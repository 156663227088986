@import '../style/colors.scss';

div.destination-stats--container {
  h2 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 0;
    color: $white;
    padding: 10px;
    line-height: 1.6;
    background: $pink;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .destination-stats--loading-box {
    width: 100%;
    height: 60px;

    .loading-indicator-fading-circle {
      width: 40px;
      height: 40px;
    }
  }

  .destination-stats--flex-box {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    .destination-stats--last-vistiors {
      margin-right: 10px;
    }

    .destination-stats--top-vistiors {
      margin-left: 10px;
    }

    div {
      flex: 1;
      font-size: 14px;
      min-width: 180px;

      ul {
        background: $mapColor;
        border: 1px solid $mapColor;
        font-size: 12px;
        list-style: none;
        border-radius: 2px;
        padding: 10px;

        li {
          list-style-position: inside;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          span:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .destination-stats--no-visitors {
    font-style: italic;
  }
}
