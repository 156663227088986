@import '../style/colors.scss';
@import '../style/mixins.scss';

.login-page--session-expired-container {
  width: 100%;
  background: $warning;
  padding: 20px;
  border-radius: 4px;
  border: 2px solid darken($warning, 5);
}

.login-form {
  position: relative;
  padding-bottom: 60px;

  .login-form--control {
    width: 100%;
  }

  .login-form--control label {
    margin-top: 20px;
    font-weight: 100;
  }
  .login-form--control input {
    @include form-control-style();
  }
  .login-form--error {
    color: $red;
    display: block;
  }

  .login-form--submit {
    @include custom-buttons();
    background: $blue;
    border-radius: 4px;
    padding: 6px 20px;
    text-transform: uppercase;
  }

  .login-form--children {
    .loader {
      position: absolute;
    }
  }
}
