/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./fonts/open-sans-v27-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/open-sans-v27-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./fonts/open-sans-v27-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/open-sans-v27-latin-600.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./fonts/open-sans-v27-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/open-sans-v27-latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* mulish-regular - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./fonts/mulish-v10-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/mulish-v10-latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* mulish-700 - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./fonts/mulish-v10-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/mulish-v10-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* mulish-900 - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("./fonts/mulish-v10-latin-900.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/mulish-v10-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
