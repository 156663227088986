@import '../style/colors.scss';
@import '../style/mixins.scss';

.reset-password-form {
  position: relative;
  padding-bottom: 80px;

  &--control {
    width: 100%;

    label {
      margin-top: 20px;
      font-weight: 100;
    }

    input {
      @include form-control-style();
    }
  }

  &--info-text {
    margin: 20px;
    padding: 15px;
    border: 3px dotted $light-green;

    > a {
      margin-top: 15px;
    }
  }

  &--error {
    color: red;
    display: block;
    margin-bottom: -12px;
  }

  &--submit {
    @include custom-buttons();
  }

  &--cancel {
    @include custom-buttons();
    background-color: $grey;
    margin-right: 210px;
  }
}
