@import '../style/colors.scss';
@import '../style/mixins.scss';

.footer--container {
  background: $themeColor;
  color: darken($light-blue, 15);
  font-size: 12px;
  width: 100%;

  .footer--content {
    max-width: 960px;
    padding: 20px;
    margin: 0 auto;

    a {
      color: darken($light-blue, 15);
      text-decoration: underline;
    }

    .footer--flex-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 20px;

      div {
        flex: 1 1 250px;
        padding: 10px 10px;
      }
    }

    p {
      text-align: center;
      margin: 0;
    }
  }
}

.tell-tur-cms--main-subcontent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 60px 0;
  position: relative;
  z-index: 10;
  color: $white;

  @media (max-width: 976px) {
    flex-direction: column;
  }

  @media (max-width: 643px) {
    padding: 60px 20px;
  }

  h2.sub-header {
    font-size: 21px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 20px;
    white-space: nowrap;

    @media (max-width: 768px) {
      font-size: 19px;
    }
  }

  &-box {
    max-width: 420px;
    flex: 1 1 420px;

    &:nth-child(1) {
      margin-right: 120px;
      @media (max-width: 976px) {
        margin-right: auto;
      }
    }
    @media (max-width: 976px) {
      flex: 1 1 100%;
      margin: 0 auto;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      & > a {
        color: $white;
        text-decoration: underline;
      }
    }
  }

  &::before {
    @include absolute(0, 0, 0, 0);
    content: '';
    z-index: -10;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 121.18" width="64" height="64"><path d="M166.57,1.94C132.34,6.62,114.06,14.08,70.77,41c-20.61,12.84-46.16,21.37-64,21.37C-.4,62.41,0,60.64,0,92.25v28.59l6.41-.57c41.24-3.7,62.6-10.8,99.6-33.15,6.62-4,12.49-7.27,13-7.27s2.93-1.22,5.29-2.71c39-24.65,101-25.13,142.14-1.09,3.21,1.87,6.66,3.72,7.66,4.1S281.38,84,288,87.91c36.92,21.73,63.79,30.33,102,32.66l10,.61V92.31c0-22.41-.28-28.87-1.28-28.87-31.31-.09-53.55-7.75-91.54-31.53-24.32-15.23-55.13-26.8-78.46-29.47L215.42.88C202.8-.62,182-.17,166.57,1.94" fill="rgb(0,59,92)"/></svg>')
        0 0,
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 121.18" width="64" height="64"><path d="M166.57,1.94C132.34,6.62,114.06,14.08,70.77,41c-20.61,12.84-46.16,21.37-64,21.37C-.4,62.41,0,60.64,0,92.25v28.59l6.41-.57c41.24-3.7,62.6-10.8,99.6-33.15,6.62-4,12.49-7.27,13-7.27s2.93-1.22,5.29-2.71c39-24.65,101-25.13,142.14-1.09,3.21,1.87,6.66,3.72,7.66,4.1S281.38,84,288,87.91c36.92,21.73,63.79,30.33,102,32.66l10,.61V92.31c0-22.41-.28-28.87-1.28-28.87-31.31-.09-53.55-7.75-91.54-31.53-24.32-15.23-55.13-26.8-78.46-29.47L215.42.88C202.8-.62,182-.17,166.57,1.94" fill="rgb(0,59,92)"/></svg>')
        0 50px rgb(0, 68, 92);
    background-size: 100px 40px;
  }
  &::after {
    @include absolute(0, 0, 0, 0);
    content: '';
    z-index: -9;
    background: $themeColor;
    background: linear-gradient(180deg, rgba($themeColor, 1) 30%, rgba($themeColor, 0) 100%);
  }
}

.tell-tur-cms--main-content {
  padding: 80px;
  color: $white;
  font-size: 16px;
  line-height: 28px;

  div {
    max-width: 1280px;
    margin: 0 auto;
    z-index: 10;
    img {
      padding: 0 5px;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .tell-tur--app-badge-container {
    margin-top: 20px;
    text-align: center;
    height: 55px;
  }

  .tell-tur-cms--main-content__description {
    padding: 0 0 40px;
    max-width: 960px;

    h2 {
      font-size: 32px;
      text-transform: uppercase;
      color: $light-green;
      font-weight: 900;
      margin: 0 0 40px 50%;
      transform: translateX(-50%);
      white-space: nowrap;

      @media (max-width: 768px) {
        white-space: normal;
        text-align: center;
        margin: 0 auto 40px auto;
        transform: translateX(0);
      }
    }

    .strong {
      font-weight: 700;
      font-size: 18px;

      .link:hover::before {
        height: 26px;
      }
    }
    p:not(.strong) {
      margin-top: 50px;
      font-size: 16px;
    }
    .icon {
      @include square(24px);
      fill: $light-green;
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;

      svg {
        @include square(24px);
      }
    }

    .link {
      position: relative;
      text-decoration: none;
      color: $white;
      z-index: 100;
      &::before {
        content: '';
        @include absolute($bottom: -2px, $left: 0);
        @include size(100%, 2px);
        display: block;
        background: rgba($light-green, 0.3);
        transition: height 0.1s linear;
        z-index: -1;
      }
      &:hover::before {
        height: 22px;
      }
    }
  }
}
