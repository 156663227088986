@import '../style/mixins';
@import '../style//colors.scss';

.editable-search-dropdown--open {
  position: relative;
  z-index: 2;
}

.editable-search-dropdown {
  &--value {
    font-weight: 700;
    padding-right: 15px;
    display: inline;
  }

  &--edit-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .dropdown-container {
      flex: 1 1 150px;
      margin-right: 10px;
      display: block;

      .editable-search-dropdown__control {
        height: 44px;
        border-radius: 0;
        box-shadow: none;
        background: #fff;
      }

      .editable-search-dropdown__control--is-focused {
        border: 2px solid darken($light-blue, 15%);
      }
    }

    button {
      flex: 0 0 100px;

      @include btn-common-properties();
      height: auto;
      border-color: $blue;
      background: $blue;

      padding-left: 20px;
      padding-right: 20px;
    }

    &.collapsible {
      max-height: 600px;
      transition: all 0.2s ease;
    }

    &.closed {
      max-height: 0;
      transition: all 0.2s ease;
    }

    input {
      flex: 1 1 150px;
      margin-right: 10px;
      display: block;
    }
  }

  &--dropdownlist {
    border: 1px solid $grey;
    margin-top: -35px;
    margin-left: 50px;
    > div {
      padding: 10px 10px;
      cursor: pointer;

      &:hover {
        background-color: $grey;
      }
    }

    .selected {
      color: $green;
    }
  }

  &--edit-toggle {
    display: inline-block;
    margin-left: 15px;
    padding-bottom: 5px;

    > span > svg {
      @include square(12px);
      fill: $black;
      cursor: pointer;
    }
  }
}
