@import "../style/colors.scss";

.telltur-search--container {
  width: 480px;
  position: absolute;
  top: 68px;
  right: 0px;
  z-index: 100000;
  color: $black;

  @media (max-width: 768px) {
    width: 100%;
  }

  .telltur-search--inputWrap {
    width: 100%;
    display: table;
    margin: 0 auto;
    background-color: $blue;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    input {
      width: calc(100% - 20px);
      font-size: 16px;
      line-height: 1.5;
      padding: 9px 5px;
      margin: 10px 10px;
      border: none;
      border-radius: 0;
      float: left;
    }
    ::-webkit-input-placeholder {
      font-style: italic;
    }
    :-moz-placeholder {
      font-style: italic;
    }
    ::-moz-placeholder {
      font-style: italic;
    }
    :-ms-input-placeholder {
      font-style: italic;
    }

    .telltur-search--clear-btn {
      background: transparent;
      height: 32px;
      width: 32px;
      float: right;
      margin-top: -46px;
      margin-right: 20px;

      .icon svg {
        width: 28px;
        height: 28px;
        fill: $grey;
      }
    }
  }

  .telltur-search--results-wrapper {
    width: calc(100% - 20px);
    margin: -10px auto;

    .telltur-search--loadingElem {
      text-align: center;
      margin: 0 auto;
      height: 70px;
      background: #fff;
      border: 1px solid #ddd;
      z-index: 1000;

      .loading-indicator-fading-circle {
        top: calc(50% - 20px);
        height: 40px;
        width: 40px;
      }
    }

    .telltur-search--searchSources:not(:empty) {
      max-height: calc(100vh - 140px);
      overflow: auto;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
      border: 1px solid #eee;
      pointer-events: all;
    }

    .telltur-search--hideSources {
      display: none;
    }

    .telltur-search--searchSources {
      background: $white;
      max-height: none;

      .searchSource--container {
        ul {
          li {
            h3 {
              font-weight: 600;
              padding-bottom: 4px;
            }
          }
        }
      }
    }
  }
}
