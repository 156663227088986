@import '../../style/colors.scss';

.modal--content {
  padding: 30px;
  color: $secondaryFontColor;

  h2 {
    margin: 0 0 20px 0;
  }

  .tell-tur-form.no-padding .tell-tur-form--control {
    select {
      margin-top: 15px;
    }
  }

  .text-danger {
    margin: 10px 0;
  }

  .text-success {
    margin: 10px 0;
  }

  .btn-flex-container {
    display: flex;
    justify-content: center;
    .btn + .btn {
      margin-left: 20px;
    }
    .btn {
      display: inline-block;
      margin-top: 20px;
      &-primary {
        &:active,
        &:focus {
          background: darken($light-green, 5);
        }
      }
    }
  }
}
